import React from "react";
import VideoSection from "../components/videoList/videoSection";

class AEnvelopeVideos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videos: [],
            selectedVideo: null
        };
    }

    componentDidMount() {
        this.setVideos();
    }

    setVideos() {
        const videos = [
            {
                id: '-gyJcuf0t-M',
                title: 'A-envelope as a regular 4 channels ADSR',
                description: 'An overview of the A-envelope as a regular 4 channels ADSR.',
                thumbnail: 'https://img.youtube.com/vi/-gyJcuf0t-M/sddefault.jpg'
            },
            {
                id: '8DQUIKCj6yc',
                title: 'Input activation modes',
                description: 'A-envelope Gate, Trigger and Retrigger input modes explained.',
                thumbnail: 'https://img.youtube.com/vi/8DQUIKCj6yc/sddefault.jpg'
            },
            {
                id: 'emGtU61k2ck',
                title: 'Loop function',
                description: 'A-envelope Loop and Auto-Loop functions explained.',
                thumbnail: 'https://img.youtube.com/vi/emGtU61k2ck/sddefault.jpg'
            },
            {
                id: 'CBSXczZl_NE',
                title: 'BEOS outputs',
                description: 'A-envelope BEOS outputs explained.',
                thumbnail: 'https://img.youtube.com/vi/CBSXczZl_NE/sddefault.jpg'
            },
            {
                id: '3-DqSqojKhc',
                title: 'Modulation inputs',
                description: 'A-envelope Modulation inputs explained.',
                thumbnail: 'https://img.youtube.com/vi/3-DqSqojKhc/sddefault.jpg'
            },
            {
                id: 'miXIQsWqRrI',
                title: 'Channel association',
                description: 'A-envelope Channel association. How it works',
                thumbnail: 'https://img.youtube.com/vi/miXIQsWqRrI/sddefault.jpg'
            },
            {
                id: 'F216mZKSYvw',
                title: 'Firmware update procedure',
                description: 'A-envelope Firmware update procedure. How to example',
                thumbnail: 'https://img.youtube.com/vi/F216mZKSYvw/sddefault.jpg'
            },
        ];

        this.setState({
            videos: videos,
            selectedVideo: videos[0]
        });
    };

    onVideoSelect = video => {
        this.setState({
            selectedVideo: video
        });
    };

    render() {
        const { videos, selectedVideo } = this.state;
        const videoSectionProps = {
            videos,
            selectedVideo,
            onVideoSelect: this.onVideoSelect
        };
        return (
            <div>
                <VideoSection {...videoSectionProps} />
            </div>
        );
    }
}

export default AEnvelopeVideos;
