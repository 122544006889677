import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExternalLink, { ExternalLinkText } from '../externalLink';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'left',
    overflow: 'hidden',
    height: 25,
    marginBottom: 6,
  },
  font: {
    color: '#FF0000'
  }
}));

export default function RetailerItem({ key, retailer, onSelect }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div>
        <ExternalLinkText href={retailer.url} children={retailer.name} />
      </div>
    </div>
  );
}
