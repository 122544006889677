import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import RetailerItem from './retailerItem';


const useStyles = makeStyles((theme) => (
  {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      '& > *': {
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(0),
          marginTop: theme.spacing(1),
          marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(0),
          marginTop: theme.spacing(0),
          marginBottom: theme.spacing(1),
        },
      },
    }
  }
));

const countryListStyles = makeStyles((theme) => (
  {
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      marginRight: theme.spacing(6),
      '& > *': {
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(0),
          marginTop: theme.spacing(0),
          marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(0),
          marginTop: theme.spacing(0),
          marginBottom: theme.spacing(1),
        },
      },
    }
  }
));

const RetailerList = ({ retailers, onRetailerSelect }) => {

  const classes = useStyles();
  const countryListclasses = countryListStyles();

  const list = Object.keys(retailers).map(country => (
    <div className={countryListclasses.root}>
      <h3>{retailers[country].country}</h3>
      <Paper elevation={0}>
        {
          retailers[country].retailers.map(retailer => (
            <RetailerItem
              key={retailer.id}
              retailer={retailer}
              onSelect={onRetailerSelect}
            />
          ))
        }
      </Paper>
    </div>
  ));

  return <ul className={classes.root}>{list}</ul>;
};

export default RetailerList;
