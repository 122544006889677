import React from "react";
import styled from "styled-components";

import ImageGridList from "./imageGridList";

const StyledImageSection = styled.ul`
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    padding: 16px 0 16px 16px;

    > div {
      &:first-child {
        min-width: 60%;
      }
      &:last-child {
        flex: 1;
      }
    }
  }
`;


export default function ImageSection({ images, selectedImage, onImageSelect }) {

  return (
    <StyledImageSection>
      <ImageGridList images={images} onImageSelect={onImageSelect} />
    </StyledImageSection>
  );
};
