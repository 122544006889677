import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import FirmwareIcon from '../assets/firmware.svg';
import ReleaseNotesIcon from '../assets/release-notes.svg';

import Button from '@material-ui/core/Button';


function createDocument(name, content, downloadName) {
    return { name, content, downloadName };
}

function DownloadButton({ document }) {

    return (
        <Button
            href={document.content}
            download={document.downloadName}
            variant="outlined"
            rel="noopener noreferrer"
            style={{ display: "table-cell" }}>
            {document.name}
        </Button>
    );
}

const FirmwareUpdateList = ({ data }) => {

    const useStyles = makeStyles({
        root: {
            width: '100%',
            maxWidth: 550,
        },
    });

    const classes = useStyles();
    const firmware = data.firmware;
    const releaseNotes = data.releaseNotes;

    return (
        <Box>
            <List className={classes.root}>
                {firmware.edges.map((file) => (
                    <ListItem key={file.node.relativePath} style={{ paddingLeft: 0, paddingRight: 0, flexWrap: "wrap" }}>
                        <ListItemAvatar style={{ marginRight: 16 }} >
                            <FirmwareIcon />
                        </ListItemAvatar>
                        <ListItemText style={{ marginBottom: 16 }} primary={file.node.relativePath} secondary={new Date(file.node.modifiedTime).toLocaleString()} />
                        <DownloadButton document={createDocument("Download", file.node.publicURL, file.node.relativePath)} />
                    </ListItem>
                ))
                }
            </List >
            <List className={classes.root}>
                {releaseNotes.edges.map((file) => (
                    <ListItem key={file.node.relativePath} style={{ paddingLeft: 0, paddingRight: 0, flexWrap: "wrap" }}>
                        <ListItemAvatar style={{ marginRight: 16 }} >
                            <ReleaseNotesIcon />
                        </ListItemAvatar>
                        <ListItemText style={{ marginBottom: 16 }} primary={file.node.relativePath} secondary={new Date(file.node.modifiedTime).toLocaleString()} />
                        <DownloadButton document={createDocument("Download", file.node.publicURL, file.node.relativePath)} />
                    </ListItem>
                ))
                }
            </List >
        </Box>
    );
}

function AEnvelopeFirmwareUpdates(props) {
    return (
        <StaticQuery
            query={graphql`
                query aenvelopeFirmwareQuery {
                    firmware: allFile(filter: {
                        extension: {regex: "/hex/"}
                        relativePath: {regex: "/latest/"}
                    }) {
                    edges {
                        node {
                        name
                        extension
                        dir
                        modifiedTime
                        relativePath
                        size
                        publicURL
                        }
                    }
                    }
                    releaseNotes: allFile(filter: {
                        extension: {regex: "/txt/"}
                        relativePath: {regex: "/latest/"}
                    }) {
                    edges {
                        node {
                        name
                        extension
                        dir
                        modifiedTime
                        relativePath
                        size
                        publicURL
                        }
                    }
                    }
                }
        `}
            render={data => <FirmwareUpdateList data={data} {...props} />}
        />
    )
}

export default AEnvelopeFirmwareUpdates
