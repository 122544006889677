import React from "react";
import { makeStyles } from '@material-ui/core/styles';

import RetailerLogo from './retailerLogo';


const useStyles = makeStyles((theme) => (
  {
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      //width: "100%",
      marginRight: theme.spacing(0),
      marginLeft: theme.spacing(0),
      '& > *': {
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(0),
          marginTop: theme.spacing(0),
        },
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(0),
          marginTop: theme.spacing(0),
        },
      },
    }
  }
));

const countryListStyles = makeStyles((theme) => (
  {
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      '& > *': {
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(0),
          marginTop: theme.spacing(0),
          justifyContent: 'center',
          alignItems: 'center',
        },
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(0),
          marginTop: theme.spacing(0),
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    }
  }
));

const RetailerLogoList = ({ retailers, onRetailerSelect }) => {

  const classes = useStyles();
  const countryListclasses = countryListStyles();

  const list = Object.keys(retailers).map(country => (
    <div className={countryListclasses.root}>
      {
        retailers[country].retailers.map(retailer => (
          <div>
            {(retailer.logo != null) &&
              <RetailerLogo
                key={retailer.id}
                retailer={retailer}
                onSelect={onRetailerSelect}
              />
            }
          </div>
        ))
      }
    </div>
  ));

  return <ul className={classes.root}>{list}</ul>;
};

export default RetailerLogoList;
