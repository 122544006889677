import React from "react";
import styled from "styled-components";

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';


const YOUTUBE_EMBEDD_URL = "https://www.youtube.com/embed/";

const IframeContainer = styled.div`
  width: 100%;
  height: auto;

  > div {
    position: relative;
    padding-top: 56%;
  }

  iframe {
    position: absolute;
    height: 70%;
    width: 100%;
  }
`;

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        marginBottom: 45,
    },
    media: {
        height: 700,
    },
});

export const VideoDetail = ({ video }) => {
    const styles = useStyles();

    return <CustomVideoDetail video={video} styles={styles} />
}

export const CustomVideoDetail = ({ video, styles }) => {

    if (!video) return null;

    const { id, title, description } = video;
    const videoSrc = `${YOUTUBE_EMBEDD_URL}${id}?rel=0`;

    return (
        <Card className={styles.root}>
            <CardActionArea>
                <CardMedia
                    className={styles.media}
                    image={videoSrc}
                    title={title}
                >
                    <IframeContainer>
                        <iframe
                            src={videoSrc}
                            title={title}
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            frameBorder="0"
                        />
                    </IframeContainer>
                </CardMedia>
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

