import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';


const onVideoSelect = (video, onSelect) => {
    if (onSelect) {
        onSelect(video);
    }
};

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
        marginBottom: 15,
    },
    media: {
        height: 150,
    },
});

const VideoItem = ({ video, onSelect, ...otherProps }) => {
    const classes = useStyles();

    if (!video) return null;

    const { id, title, description, thumbnail } = video;

    return (
        <Card className={classes.root}
            onClick={onVideoSelect.bind(null, video, onSelect)}
            {...otherProps}>
            <CardActionArea>
                <CardMedia
                    className={classes.media}
                    image={thumbnail}
                    title={title}
                >
                </CardMedia>
                <CardContent>
                    <Typography gutterBottom variant="body2" color="textSecondary">
                        {title}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}

export default VideoItem;
