import React from 'react';
import ImageSection from '../components/imageList/imageSection';
import front from '../images/a-envelope/front.jpg';
import rear from '../images/a-envelope/rear.jpg';
import sideLeft from '../images/a-envelope/sideLeft.jpg';
import sideRight from '../images/a-envelope/sideRight.jpg';
import perspective from '../images/a-envelope/perspective.jpg';


class AEnvelopeImages extends React.Component {

    constructor(props) {
        super(props);

        const imageData = [
            {
                img: front,
                title: 'A-envelope front view',
                author: 'Velectronic',
                cols: 6,
            },
            {
                img: sideLeft,
                title: 'A-envelope left side view',
                author: 'Velectronic',
                cols: 3,
            },
            {
                img: sideRight,
                title: 'A-envelope right side view',
                author: 'Velectronic',
                cols: 3,
            },
            {
                img: rear,
                title: 'A-envelope rear view',
                author: 'Velectronic',
                cols: 6,
            },
            {
                img: perspective,
                title: 'A-envelope perspective view',
                author: 'Velectronic',
                cols: 6,
            },
        ];

        this.state = {
            images: imageData,
            selectedImage: imageData[0]
        };
    }

    componentDidMount() {
    }

    onImageSelect = image => {
        this.setState({
            selectedImage: image
        });
    };

    render() {

        const { images, selectedImage } = this.state;

        return (
            <div>
                <ImageSection images={images} selectedImage={selectedImage} onImageSelect={this.onImageSelect} />
            </div>
        );
    }
}

export default AEnvelopeImages;
