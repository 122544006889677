import styled from "@emotion/styled"

import rem from "../utils/rem"
import "../styles/keyframes.css"

const Pulse = styled.div`
  position: absolute;
  height: ${rem(300)};
  width: ${rem(300)};
  border-radius: 100%;
  border: 1px solid #fff;
  display: block;
  opacity: 0;
  box-sizing: border-box;
  animation: 1s ${props => props.start}s ${props => props.animation} infinite
    linear;
`

export default Pulse
