import React from "react";
import VideoItem from "./videoItem";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles((theme) => (
    {
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            '& > *': {
                [theme.breakpoints.up('sm')]: {
                    marginLeft: theme.spacing(0),
                    marginTop: theme.spacing(0),
                },
                [theme.breakpoints.down('xs')]: {
                    marginLeft: theme.spacing(0),
                    marginTop: theme.spacing(1),
                },
            },
        }
    }
));

const VideoList = ({ videos, onVideoSelect }) => {

    const classes = useStyles();

    const list = videos.map(video => (
        <VideoItem
            key={video.title}
            video={video}
            as="li"
            onSelect={onVideoSelect}
        />
    ));

    return <ul className={classes.root}><Paper elevation={0}>{list}</Paper></ul>;
};

export default VideoList;
