import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import rem from '../utils/rem';
import flex from '../styles/flex';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

/*
Example:

        <ParameterValueTable rows={[
            createData('Input impedance', '100kOhms'),
            createData('Maximum input voltage', '+/- 10 Volts'),
            createData('Bandwidth', 'DC to 20kHz'),
        ]}>

*/
export default function ParameterValueTable({ rows }) {

    const useStyles = makeStyles({
        table: {
            minWidth: 300,
        },
    });

    const classes = useStyles();

    return (<TableContainer style={{ ...flex.vertical, padding: `${rem(0)} ${rem(500)} ${rem(50)} ${rem(50)}` }} >
        <Table className={classes.table} size="small" aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell weight="semibold" style={{ color: "#1C1B1F" }}>Parameter</TableCell>
                    <TableCell align="right">Value</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row) => (
                    <TableRow key={row.name}>
                        <TableCell component="th" scope="row" style={{ color: "#4B5E6C" }}>{row.name}</TableCell>
                        <TableCell align="right" style={{ color: "#4B5E6C" }}>{row.value}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>)
}
