import React from "react";
import RetailerSection from "../components/retailerList/retailerSection";

import retailerLogoDupertuis from '../images/retailers/dupertuis.png';
import retailerLogoElevatorSound from '../images/retailers/elevator_sound.png';
import retailerLogoPerfectCircuit from '../images/retailers/perfect_circuit.png';
import retailerLogoPostModular from '../images/retailers/post_modular.png';
import retailerLogoRubadub from '../images/retailers/rubadub.png';
import retailerLogoShneidersLaden from '../images/retailers/schneidersladen.png';
import retailerLogoSignalSounds from '../images/retailers/signal_sounds.png';
import retailerLogoVelectronic from '../images/retailers/velectronic.png';


class AEnvelopeRetailers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            retailers: [],
            selectedRetailer: null
        };
    }

    componentDidMount() {
        this.setRetailers();
    }

    setRetailers() {
        const retailers = [
            {
                country: 'England',
                retailers: [
                    {
                        id: 'elevatorsound.com',
                        name: 'Elevator Sound',
                        url: 'https://www.elevatorsound.com/product/velectronic-a-envelope-eurorack-quad-envelope-module/',
                        logo: retailerLogoElevatorSound,
                        logoWidth: 120
                    },
                    {
                        id: 'postmodular.co.uk',
                        name: 'Post Modular',
                        url: 'https://postmodular.co.uk/modules/a-envelope/',
                        logo: retailerLogoPostModular,
                        logoWidth: 120
                    }
                ]
            },
            {
                country: 'Germany',
                retailers: [
                    {
                        id: 'schneidersladen.de',
                        name: 'SchneidersLaden',
                        url: 'https://schneidersladen.de/en/velectronic-associative-quadruple-envelope',
                        logo: retailerLogoShneidersLaden,
                        logoWidth: 200
                    }]
            },
            {
                country: 'Scotland',
                retailers: [
                    {
                        id: 'rubadub.co.uk',
                        name: 'RUBADUB',
                        url: 'https://rubadub.co.uk/products/velectronic-a-envelope-associative-quadruple-envelope',
                        logo: retailerLogoRubadub,
                        logoWidth: 120
                    },
                    {
                        id: 'signalsounds.com',
                        name: 'Signal Sounds',
                        url: 'https://www.signalsounds.com/velectronic-a-envelope-eurorack-quad-envelope-module',
                        logo: retailerLogoSignalSounds,
                        logoWidth: 120
                    }]
            },
            {
                country: 'Spain',
                retailers: [
                    {
                        id: 'velectronic.net',
                        name: 'Velectronic',
                        url: 'mailto:info@velectronic.net?subject=A-Envelope',
                        logo: null,
                        logoWidth: 120
                    }]
            },
            {
                country: 'Switzerland',
                retailers: [
                    {
                        id: 'dupertuis.com',
                        name: 'Dupertuis',
                        url: 'https://www.dupertuis.com/shop/5000-systeme-modulaire/5400-format-eurorack/5410-module/5413-enveloppes/velectronic-associative-quadruple-envelope/',
                        logo: retailerLogoDupertuis,
                        logoWidth: 120
                    }]
            },
            {
                country: 'US',
                retailers: [
                    {
                        id: 'perfectcircuit.com',
                        name: 'Perfect Circuit',
                        url: 'https://www.perfectcircuit.com/velectronic-a-envelope.html',
                        logo: retailerLogoPerfectCircuit,
                        logoWidth: 200
                    }]
            }
        ];

        this.setState({
            retailers: retailers,
            selectedRetailer: retailers[0]
        });
    };

    onRetailerSelect = retailer => {
        this.setState({
            selectedRetailer: retailer
        });
    };

    render() {
        const { retailers, selectedRetailer } = this.state;
        const retailerSectionProps = {
            retailers,
            selectedRetailer,
            onRetailerSelect: this.onRetailerSelect
        };
        return (
            <RetailerSection {...retailerSectionProps} />
        );
    }
}

export default AEnvelopeRetailers;
