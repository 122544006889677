import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExternalLink from '../externalLink.js';


const useStyles = makeStyles((theme) => ({
  root: {
    //display: 'flex',
    //flexWrap: 'wrap',
    //justifyContent: 'space-around',
    overflow: 'hidden',
    height: 180,
    display: 'flex',
    //justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    '& > *': {
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(0),
        marginTop: theme.spacing(0),
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(0),
        marginTop: theme.spacing(0),
      },
    },
  },
}));

const countryListStyles = makeStyles((theme) => (
  {
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      '& > *': {
        [theme.breakpoints.up('sm')]: {
          marginLeft: theme.spacing(0),
          marginTop: theme.spacing(0),
        },
        [theme.breakpoints.down('xs')]: {
          marginLeft: theme.spacing(0),
          marginTop: theme.spacing(0),
        },
      },
    }
  }
));

export default function RetailerItem({ key, retailer, onSelect }) {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ExternalLink href={retailer.url} >
        <img src={retailer.logo} alt={retailer.name} width={retailer.logoWidth} />
      </ExternalLink>
    </div>
  );
}
