import React from "react";
import styled from "styled-components";
import flex from '../../styles/flex';
import rem from '../../utils/rem';

import Text, { HeaderText2, Label, SubLabel } from '../text';

import PageWrapper from '../pageWrapper';
import RetailerList from './retailerList';
import RetailerLogoList from './retailerLogoList';


const StyledRetailerSection = styled.ul`
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    padding: 16px 0 16px 16px;

    > div {
      &:first-child {
        min-width: 60%;
      }
      &:last-child {
        flex: 1;
      }
    }
  }
`;

export default function RetailerSection({ retailers, onRetailerSelect, selectedRetailer }) {

  return (
    <StyledRetailerSection>
      <PageWrapper style={{
        ...flex.vertical,
        margin: `0 auto`,
        padding: `${rem(0)} 0`,
      }}>
        <header >
          <HeaderText2>
            Our Retailers
          </HeaderText2>
        </header>
        <RetailerList retailers={retailers} onRetailerSelect={onRetailerSelect} />
        <RetailerLogoList retailers={retailers} onRetailerSelect={onRetailerSelect} />
      </PageWrapper>
    </StyledRetailerSection>
  );
};
